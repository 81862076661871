import React from "react"
import Layout from "../components/layout"

const BlogPage = () => (
    <Layout>
        <h1>Blog</h1>
        <p>Coming Soon</p>

    </Layout>
)

export default BlogPage;